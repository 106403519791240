.main-form input[type='text'],
.main-form input[type='email'],
.main-form input[type='date'],
.main-form input[type='password'],
.main-form input[type='number'],
.main-form textarea,
.main-form select{
    @apply border rounded w-full py-2 px-3 text-gray-700 min-w-[240px] focus:outline-pallate-3;
    /* @apply shadow border rounded w-full py-2 px-3 text-gray-700 min-w-[240px] focus:outline-purple-450; */
}
.main-form select{
    @apply min-w-[240px] h-[38px] block w-full bg-white px-2 py-1 rounded cursor-pointer shadow border focus:border-2 focus:border-pallate-3 focus:outline-none;
}
.main-form button, .main-form a.btn{
    @apply h-[38px] mr-2 whitespace-nowrap;
}
.main-form{
    @apply flex justify-between items-center w-full md:w-1/2 flex-wrap mx-auto;
}
.main-form.hr{
    @apply border-b border-dashed border-pallate-3 mb-5 mt-5 flex flex-col;
}
.main-form fieldset{
    @apply flex flex-col sm:flex-row justify-start sm:justify-between items-start sm:items-center w-full lg:w-1/2 pt-2 mx-auto first:pt-5 last:py-5 ;
}
.main-form fieldset.right{
    @apply justify-end;
}
.main-form fieldset.center{
    @apply justify-center flex items-center;
}
.main-form label{
    @apply min-w-[130px] max-w-[170px];
}
.btn{
    @apply px-4 py-2 text-sm cursor-pointer;
    @apply rounded text-white shadow-sm bg-pallate-3 hover:text-pallate-3 hover:bg-pallate-3/[0.5] hover:transition-transform;
}
.btn-light{
    @apply px-4 py-2 text-sm cursor-pointer;
    @apply rounded bg-pallate-3/[0.15] shadow-sm text-pallate-3 hover:bg-pallate-3 hover:text-white hover:transition-transform;
}
.btn-login{
    @apply w-fit rounded-lg bg-pallate-3 text-white py-5 leading-loose text-xl hover:bg-pallate-3/[0.8] !h-auto px-8 !whitespace-normal 2xl:!whitespace-nowrap;
}
.btn-login i{
    @apply mr-2;
}
.booking-form{
    @apply flex flex-col
}
.booking-form h4{
    @apply text-center text-pallate-3 pb-5;
}
.booking-form p{
    @apply text-center;
}
.link{
    @apply underline hover:text-pallate-3 cursor-pointer;
}
.mybooking-filter{
    @apply items-center justify-center w-full;
}
.mybooking-filter .input-group{
    @apply flex w-full mt-5;
}
.mybooking-filter .input-group input{
    @apply first:w-full mr-2;
}
.mybooking-filter .btn{
    @apply mr-2;
}
.hide{
    @apply !hidden;
}
.etc{
    @apply text-sm text-red-500; 
}