.calendar-container{
    @apply flex flex-col;
}
.nav-menu{
    @apply flex w-full py-5;
}
.nav-menu li{
    @apply pr-5 after:ml-5;
    @apply flex after:content-['>'] last:after:content-[''] after:after:ml-0;
    @apply last:text-pallate-main;
}
.nav-menu li a{
    @apply hover:text-pallate-main;
}
.content{
    @apply w-full flex flex-nowrap;
}
.content{
    @apply flex flex-col md:flex-row items-start gap-16;
}
.menu{
   @apply w-full md:w-1/4 flex flex-col justify-start !order-first md:!order-last;
}
/* .booking-btn button{
    @apply hidden md:flex;
} */
.content-container{
    /* @apply w-full md:w-3/4 pr-5; */
    @apply w-full pr-5 mx-auto md:w-3/4 ;
    @apply bg-pallate-1/[0.2] p-8 rounded-lg mb-8;
}
.content-container h3{
    @apply text-center text-pallate-main;
}
/** Table **/
.calendar table{
    @apply w-full mt-0;
}
.calendar thead,.calendar th{
    @apply text-gray-400 text-sm font-thin uppercase text-center;
}
.calendar td{
    @apply p-2 text-sm font-thin text-left hover:bg-yellow-50 cursor-pointer;
}
.calendar tr{
    @apply border-b border-dashed border-gray-200 ;
}
.calendar td.center{
    @apply text-center;
}
.calendar td.right{
    @apply text-right;
}
.day-calendar-container{
    @apply w-full flex flex-row items-start justify-start;
}
.room{
    @apply w-fit;
}
.activity-container{
    @apply w-full min-h-full overflow-y-auto;
}
.room ul{
    @apply bg-white rounded-l-lg border-dark-9 border w-fit h-full mr-[1px];
}
.room ul li{
    @apply text-[14px] h-[42px] py-2 px-8 border-b border-dark-9 last:border-b-0 whitespace-nowrap;
    @apply flex items-center justify-start first:justify-center;
}
.room .room-group{
    @apply px-4 before:content-['\25CF'] before:mr-2 before:text-xl; 
}
.room-group{
    @apply bg-dark-white-1/[0.4] hover:bg-dark-white-1/[0.4];
}
.activity-container .activity-border{
    @apply w-fit lg:w-full rounded-r-lg border-dark-9 border border-l-0;
}
.activity-container table{
    @apply w-full border-0;
}
.activity-container table tr{
    @apply flex relative flex-nowrap flex-row;
    @apply border-b border-dark-9 border-solid border-r border-l last:rounded-br-lg;
}
.activity-container table thead tr{
    @apply border-t rounded-tr-lg rounded-br-none;
}
.activity-container table tr th,.activity-container table tr td{
    @apply min-w-[60px] h-[41px] border-0 border-r last:border-r-0;
}
.activity-container table tr th{
    @apply flex justify-center items-center;
}
.activity-container table tr td{
    @apply flex flex-nowrap flex-row;
}
.activity-container table tr td .half-hr{
    @apply w-1/2 h-full last:border-l border-dashed border-dark-9;
}
.half-hr{
    @apply relative flex;
}
.rsvn-block{
    @apply absolute text-white bg-pallate-2 rounded-sm flex flex-nowrap z-10 overflow-hidden cursor-pointer;
}
.rsvn-block div{
    @apply whitespace-nowrap flex flex-nowrap pr-1;
}
.holiday{
    @apply !text-red-500;
}
.event-dot{
    @apply before:content-['\25CF'] text-xs pl-1 text-white;
}