/* Search box */
.search-modal{
    /* @apply my-auto h-fit left-0 right-0 mx-auto w-full md:w-fit  py-4 md:py-10 pl-10 pr-14 rounded z-10; */
    @apply absolute my-auto h-fit left-0 right-0 mx-auto  py-4 md:py-10 pl-10 pr-14 rounded z-10;
    @apply flex flex-col md:flex-row text-white bg-pallate-1 md:bg-pallate-2 md:items-center md:justify-center mt-5 md:mt-8;
}
.search-modal h5{
    @apply pr-5 hidden;
    @apply mb-2 md:mb-0;
}
.search-modal fieldset{
    @apply flex flex-col md:flex-row shadow-none md:shadow-lg md:bg-white text-base hover:border-pallate-main flex-nowrap items-center border-pallate-main rounded-full pl-5;
}
.search-modal button{
    @apply rounded-r-full bg-blue-pallate-4 hover:bg-blue-pallate-3 text-white flex flex-row px-5 py-2 h-[46px];
}
.search-modal select{
    @apply text-pallate-4 hover:border-pallate-main h-[46px] cursor-pointer outline-none md:rounded-l-full border-pallate-main border-r pr-3;
    @apply w-full md:w-auto mb-2 md:mb-auto pl-2 rounded;
}
.search-modal input{
    @apply pl-5 text-black h-[44px] outline-none;
    @apply w-full md:w-full xl:w-[745px] mb-2 md:mb-auto rounded;
}
.search-modal button svg{
    @apply mr-2 my-auto;
}   
.search-modal button{
    @apply hidden md:flex;
}
.search-modal .mobile-input{
    @apply p-2 py-2 mt-1 text-white flex flex-row justify-center items-center;
    @apply  md:hidden border border-blue-pallate-4 bg-blue-pallate-4 hover:bg-blue-pallate-3 hover:border-blue-pallate-2 rounded w-full;
}
/* Resource */
.resource {
    @apply flex flex-col justify-center items-center;
}
.readmore{
    @apply w-full flex items-end justify-end hover:text-pallate-main cursor-pointer py-3;
}
.resource-card{
    @apply w-full flex mt-5 flex-row flex-wrap;
}
.resource-card li{
    @apply rounded-lg w-full md:w-1/2 lg:w-1/3 xl:w-1/5 p-5;
    @apply  text-pallate-4 hover:border-pallate-4 hover:shadow-lg gap-4 cursor-pointer flex justify-center items-center;
}
.resource-card li a{
    @apply w-full h-full flex flex-row items-center justify-center;
}
.resource-card .cover{
    @apply  min-h-[50px];
}
.resource-card img{
    @apply w-full aspect-video h-[80px];
}
.resource-card p{
    @apply whitespace-normal text-left pl-5;
}
/* Ebook */
.ebook.container{
    @apply p-0 m-0 w-full flex;
}
.ebook-card{
    @apply p-0 m-0 w-full flex relative;
    /* @apply text-green-pallate-4 bg-green-pallate-1/[0.4]; */
}
.ebook-card .card{
    @apply w-full pt-5 pb-0 h-[250px] relative;
    @apply hover:text-pallate-4  cursor-pointer flex items-center;
}
.ebook-card .card .cover{
    @apply w-full flex flex-col items-center justify-start duration-300 relative
}
.ebook-card .card .cover span{
    @apply text-sm absolute hidden left-1/2 transform -translate-x-1/2 top-[10px] w-48 px-2 py-2 opacity-95 bg-pallate-3 rounded-lg text-center text-white after:content-[''] after:absolute after:left-1/2 after:top-[100%] after:-translate-x-1/2 after:border-8 after:border-x-transparent after:border-b-transparent after:border-t-pallate-3 ;
}
.ebook-card .card:hover .cover span{
    @apply flex z-50;
}
.ebook-card .cover{
    @apply  min-w-[50px];
}
.ebook-card img{
    @apply w-[153.6px] h-[200px] rounded-r-lg;
}
.ebook-card .card:hover .cover{
    /* @apply scale-110; */
}
.ebook-card p{
    @apply whitespace-normal text-center hidden pl-5;
}
.book-shelf{
    @apply w-9/12 hidden md:block p-0 m-0;
}
.book-shelf-menu {
    @apply w-3/12 flex flex-col;
}
.book-shelf-menu ul{
    @apply py-5 min-w-[136px];
}
.book-shelf-menu li{
    @apply whitespace-nowrap pr-5 cursor-pointer hover:text-green-pallate-1 hover:before:content-['•'] hover:before:pr-3;
}
.book-shelf-menu li.active{
    @apply text-green-pallate-3 before:content-['•'] before:pr-3;
}
.book-shelf-menu ul li:nth-child(2){
    @apply hover:text-pallate-3;
}
.book-shelf-menu li:nth-child(2).active{
    @apply text-pallate-3;
}
.book-shelf-menu ul li:nth-child(3){
    @apply hover:text-blue-pallate-1;
}
.book-shelf-menu li:nth-child(3).active{
    @apply text-blue-pallate-3;
}
.book-shelf-menu ul li:nth-child(4){
    @apply hover:text-yellow-pallate-1;
}
.book-shelf-menu li:nth-child(4).active{
    @apply text-yellow-pallate-3;
}
.book-shelf-menu h1{
    @apply !pb-4;
}
.book-shelf-menu .book-title{
    @apply pb-4 text-pallate-main;
}
.book-shelf-menu .book-detail{
    @apply py-5;
}
.book-shelf-menu .book-detail span{
    @apply text-pallate-3 pl-2;
}
.shelf{
    /* @apply w-full h-[20px] bg-green-pallate-1 shadow-xl;  */
    @apply invisible;
}
.journal .ebook-card {
    @apply text-pallate-4 bg-pallate-1/[0.4];
}
.journal .shelf{
    @apply bg-pallate-1;
}
.e-book .ebook-card {
    @apply text-blue-pallate-4 bg-blue-pallate-1/[0.4];
}
.e-book .shelf{
    @apply bg-blue-pallate-1;
}
.e-journal .ebook-card {
    @apply text-yellow-pallate-4 bg-yellow-pallate-1/[0.4];
}
.e-journal .shelf{
    @apply bg-yellow-pallate-1;
}
.book-shelf-mobile{
    @apply w-full flex flex-col md:hidden;
}
.book-shelf-mobile .ebook-card{
    @apply flex-col min-w-full;
}
.book-shelf-mobile .ebook-card .card{
    @apply bg-transparent;
}
.book-shelf-mobile .ebook-card img{
    @apply mx-auto;
}
.book-shelf-mobile .ebook-card li:hover img{
    @apply shadow-lg scale-110;;
}
.book-shelf-mobile .ebook-card li:hover span{
    @apply text-pallate-main;
}
.book-shelf-mobile span{
    @apply py-5 mx-auto block text-center w-2/3;
}
.book-shelf-desktop{
    @apply w-full hidden md:flex justify-end pr-10;
}
/* News */
.news{
    @apply flex flex-col justify-center items-center;
}
.news h2{
    @apply pb-5;
}
.news .readmore, .activity .readmore {
    @apply justify-center;
}
.readmore button{
    @apply bg-pallate-1 text-white px-5 py-2 cursor-pointer rounded-md hover:bg-pallate-main;
}
.news .card{
    @apply lg:h-[350px] relative flex flex-col lg:flex-row;
}
.news .image{
    @apply h-[350px] lg:min-w-[570px] aspect-square relative object-cover overflow-hidden flex items-center cursor-pointer;
}
.news .image-inner{
    @apply w-full h-full absolute bg-cover bg-center ;
}
.image .shine-box{
    @apply absolute top-0 -inset-full h-full w-1/2 z-[100] block transform -skew-x-12 bg-gradient-to-r from-transparent to-white opacity-40;
}
.card:hover .image .shine-box{
    @apply animate-shine;
}
.news .card:hover .image-inner {
    /* @apply scale-110 filter-none; */
}
.news .card .date{
    @apply flex gap-1 items-baseline pb-4 text-xl;
}
.news .card .day{
    @apply first-letter:text-pallate-4 text-4xl;
}
.news .card .title{
    @apply hover:text-pallate-main cursor-pointer break-all min-w-fit lg:min-w-fit;
}
.news .card .info{
    @apply py-8 px-0 lg:px-14;
}
.news .detail{
    @apply whitespace-pre-line text-gray-500 py-5;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
.news .card .viewmore{
    @apply text-pallate-4 hover:underline py-5 cursor-pointer;
}
.activity{
    @apply mt-8 flex flex-col;
}
.activity .title{
    @apply relative h-[80px] block;
}
.activity .all-events{
    @apply col-span-2 flex justify-end;
}
.activity .title-h1{
    @apply block absolute top-0 p-0 left-0;
}
.activity .title-h1 h1{
    @apply p-0;
}
.activity .menu {
    @apply col-span-2 md:col-span-1 flex justify-end pb-5;
}
.activity .menu ul{
    @apply flex flex-row;
}
.activity .menu ul li{
    @apply px-5 border-r whitespace-nowrap last:border-r-0 cursor-pointer hover:text-purple-3;
}
.activity .container{
    @apply grid grid-cols-2 gap-4;
}
.activity .container .activity-container{
    @apply col-span-2;
    @apply p-0 m-0 w-screen pt-2;       /* Need to defind for SwiperNavigation */
}
.activity-container .card{
  @apply min-h-[440px] bg-white relative flex flex-col cursor-pointer rounded-lg shadow-lg mb-5;
}
.activity-container .card .image{
    @apply h-[220px] w-full relative object-cover overflow-hidden flex items-center rounded-t-lg;
}
.activity-container .card .image-inner{
    /* @apply shadow-[0_9px_0_rgb(0,0,0)] hover:shadow-[0_4px_0px_rgb(0,0,0)] text-black bg-white ease-out hover:translate-y-1 transition-all rounded; */
    @apply shadow-[0_9px_0_rgb(0,0,0)] text-black bg-white ease-out transition-all rounded;
}
.activity-container .card .detail{
    @apply whitespace-pre-line line-clamp-2;
}
.activity-container .card .title{
    @apply w-full text-left h-auto pb-2 ;
}
.activity-container .card .title h4{
    /* @apply text-ellipsis truncate ; */
}
.activity-container .card:hover .title h4{
    @apply text-pallate-main;
}
.activity-container .card .info{
    @apply px-5 py-5;
}
.activity-container .card .image-inner{
    /* @apply w-full h-full absolute bg-cover bg-center transition-all duration-500 ease-in-out transform contrast-50; */
    @apply w-full h-full absolute bg-cover bg-center ;
}
.activity-container .card:hover .image-inner {
  /* @apply scale-110 filter-none; */
}
.activity-container .card-end {
    @apply absolute -bottom-0 right-0 w-0 h-2 bg-red-400;
    background: 
      linear-gradient(var(--color-text-5) 0 0) no-repeat
       calc(200% - var(--p,0%)) 100% / 200% var(--p,.08em);
    transition: .3s var(--t,0s), background-position .3s calc(.3s - var(--t,0s));
}
/* Calendar */
.calendar{
    @apply flex flex-col lg:flex-row;
}
.calendar-detail{
    @apply flex w-full pb-5 lg:pb-0 px-[100px];
    @apply justify-center items-center;
}
.calendar-detail .frame{
    @apply  p-4 w-full h-fit bg-pallate-bg rounded-lg;
}
.calendar-detail .frame{
    @apply w-full;
}
.calendar-group{
    @apply w-full flex flex-col;
}
.calendar-group h2{
    @apply pb-8 text-center;
}
/* VDO */
.vdo-detail{
    @apply flex w-full h-[400px] lg:h-[600px] gap-0 justify-start items-start flex-row flex-wrap;
}
.vdo-detail .card{
    @apply w-full pb-5 pl-5;
}
.vdo-detail .card img{
    @apply cursor-pointer;
}
.vdo-detail .iframe{
    @apply aspect-video w-full rounded-lg;
}
.vdo-detail .cover{
    @apply w-full h-full invisible lg:visible bg-contain bg-right bg-no-repeat z-0;
}